/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://kv77nt52p5dalfwpsj3j7dijo4.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_kJeZ3llIf",
    "aws_user_pools_web_client_id": "7gkrjhks9c7c7536aqab9hclgt",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "AuditTrail-AuthenticatedOperations-demo",
            "region": "us-east-1"
        },
        {
            "tableName": "AuditTrail-EventNames-demo",
            "region": "us-east-1"
        },
        {
            "tableName": "BQAnalyticsCategories-demo",
            "region": "us-east-1"
        },
        {
            "tableName": "BQAnalyticsEventLog-demo",
            "region": "us-east-1"
        },
        {
            "tableName": "BQAnalyticsExercises-demo",
            "region": "us-east-1"
        },
        {
            "tableName": "BQAnalyticsPatients-demo",
            "region": "us-east-1"
        },
        {
            "tableName": "BQAnalyticsTreatments-demo",
            "region": "us-east-1"
        },
        {
            "tableName": "BQAppEvents-demo",
            "region": "us-east-1"
        },
        {
            "tableName": "MobileDeviceInfo-demo",
            "region": "us-east-1"
        },
        {
            "tableName": "SharedData-demo",
            "region": "us-east-1"
        },
        {
            "tableName": "SimMapping-demo",
            "region": "us-east-1"
        },
        {
            "tableName": "TreatmentDefinitions-demo",
            "region": "us-east-1"
        },
        {
            "tableName": "UniqueValues-demo",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "bq-content152700-demo",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
